import { createStore } from 'vuex'
import sprf from './modules/sprf'
import company from './modules/company'
import price from './modules/price'
import sdh from './modules/sdh'
import pdh from './modules/pdh'
import product from './modules/product'
import user from './modules/user'
import quotation from './modules/quotation'
import reseller from './modules/reseller'
import end_user from './modules/end_user'
import load_license from './modules/load_license'
import ppn from './modules/ppn'
import pipeline from './modules/pipeline'
import invoice from './modules/invoice'
import delivery_order from './modules/delivery_order'
import performa_invoice from './modules/performa_invoice'
import insentif from './modules/insentif'
import elsa from './modules/elsa'
import nomor_surat from './modules/nomor_surat'
import contact from './modules/contact'
import ihp from './modules/ihp'
import ih from './modules/ih'
import opsi_quotation from './modules/opsi_quotation'
import client_id from './modules/client_id'
import persentase from './modules/percentase'
import partner_contact from './modules/partner_contact'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  actions: {},
  modules: {
    sprf,
    company,
    price,
    sdh,
    pdh,
    product,
    user,
    quotation,
    reseller,
    end_user,
    load_license,
    ppn,
    invoice,
    delivery_order,
    performa_invoice,
    insentif,
    elsa,
    nomor_surat,
    contact,
    ihp,
    ih,
    opsi_quotation,
    client_id,
    pipeline,
    persentase,
    partner_contact,
  },
})
